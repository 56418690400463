import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { StaticImage as Img } from "gatsby-plugin-image"
import { Link } from "~components"
import Hero from "./style"
export default function HeroSection() {
  return (
    <Hero backgroundColor="rgba(157, 196, 188, 0.2)">
      <Container>
        <Row className="align-items-center justify-content-center">
          {/* Welcome content Area */}
          <Col className="col-xxl-6 col-lg-7 col-md-9 col-xs-11 order-1" style={{marginTop: '8rem'}}>
            <Hero.Content>
              <Hero.Title as="h1">
                Your Pet's Information{" "}
                <br className="d-none d-xs-block d-lg-none d-xl-block" />
                All in one place
              </Hero.Title>
              <Hero.Text fontColor="rgba(38, 39, 41, 0.7)">
                Luna Journal helps your household track your pet's adventures,
                medications, vaccinations, and more all in one central place.
                When you track something that's relevant to your vet, let us
                know and we'll send it to them for you.
              </Hero.Text>
              <Hero.ButtonGroup>
                <Hero.Button className="btn-red 🌱" as={Link} to="https://www.youtube.com/watch?v=pWU1yEDWHqg">
                  Watch Video
                </Hero.Button>
                <Hero.Button className="btn-green" as={Link} to="#cta">
                  Get Started
                </Hero.Button>
              </Hero.ButtonGroup>
            </Hero.Content>
          </Col>
          {/*/ .Welcome Content Area */}
          {/*/ .Welcome img Area */}
          <Col
            xs="12"
            className="col-xxl-6 col-lg-5 col-md-8 col-xs-10 order-2"
          >
            <Hero.ImageGroup style={{justifyContent: 'center'}}>
              <Hero.ImageOne>
                <Img
                  className="flex"
                  layout="constrained"
                  src="../../../assets/image/home-app/luna_hero.png"
                  alt="app"
                  placeholder="blurred"
                />
              </Hero.ImageOne>
            </Hero.ImageGroup>
          </Col>
          {/*/ .Welcome img Area */}
        </Row>
      </Container>
    </Hero>
  )
}
