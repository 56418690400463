import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from '~components'
import Cta from './style'
export default function CtaSection(){
return(
<Cta id="cta">
  <Container>
    <Row className="justify-content-center">
      <Col className="col-xl-7 col-lg-10 col-md-11 text-center">
        <Cta.Box>
          <Cta.Title mb="35px">Luna Journal Is Available For Free On Google Play &amp; App Store</Cta.Title>
          <Cta.Box mt="35px">
            <Cta.Button as={Link} to="https://apps.apple.com/us/app/luna-journal/id1534437183" className="btn btn-bittersweet text-white box-shadow-1"><i className="fab fa-apple" />Apple Store</Cta.Button>
            <Cta.Button as={Link} to="https://play.google.com/store/apps/details?id=io.pyrestuios.luna_journal&hl=en_US&gl=US" className="btn btn-niagara text-white box-shadow-2"><i className="fab fa-google-play" />Google Play</Cta.Button>
          </Cta.Box>
        </Cta.Box>
      </Col>
    </Row>
  </Container>
</Cta>
)
}