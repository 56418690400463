import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import SectionTitle from './components/SectionTitle'
import Widget from './components/widget'
import { StaticImage as Img } from 'gatsby-plugin-image'
import About from './style'
export default function AboutSection(){
return(
<About backgroundColor="#f2f5fb">
  <Container>
    <Row className="justify-content-center">
      <Col className="col-xl-6 col-lg-8 col-md-9 col-xs-10 text-center">
        <SectionTitle 
        subTitle="About Luna Journal" 
        title="Helping you keep track of the information your pet needs"
        titleProps={{mb:"50px"}}
        subTitleProps={{mb:"15px"}}
        />
      </Col>
    </Row>
    <Row className="align-items-center justify-content-center">
      <Col className="col-xxl-3 col-lg-4 col-md-6 col-sm-8 col-xs-9 order-2 order-lg-1">
        <Widget directionXS="row-reverse" title="Track your pet's health" icon="fa fa-heart" text="Luna Journal helps you track all of your pet’s important information in one central application."/>
        <Widget directionXS="row-reverse" title="Never miss medication time again" icon="fa fa-tablets" text="Luna Journal uses push notifications to help remind you on their behalf. In our pet health tracker app, you can setup all the medications that your pet needs as well as medication timeslots, dosage information, and more"/>
        <Widget directionXS="row-reverse" title="Send your data to your veterinarian" icon="fa fa-user-md" text="Our pet health tracking app supports sending your pet's data to your veterinarian via SMS and Email"/>
      </Col>
      <Col xs="12" className="col-xxl-6 col-lg-4 col-md-8 col-xs-9 order-1 order-lg-2 text-center">
        <About.ImageContent>
          <Img style={{margin: '0 auto', width: '75%'}} src="../../../assets/image/home-app/luna_cta.png" alt="content" layout="fullWidth" placeholder="blurred"/>
          {/* <About.Shape>
            <Img src="../../../assets/image/home-app/green-shape.png" alt="content" layout="fullWidth" placeholder="blurred"/>
          </About.Shape> */}
        </About.ImageContent>
      </Col>
      <Col className="col-xxl-3 col-lg-4 col-md-6 col-sm-8 col-xs-9 order-3">
      <Widget directionXS="row" title="Track trends in your pet's weight" icon="fa fa-weight" text="Luna Journal helps you track your pet’s weight and and monitor trends through a weight tracking chart"/>
        <Widget directionXS="row" title="Collaborate on your pets health data" icon="fa fa-home" text="Luna Journal allows you to create a household and add people and pets to that household. All people in that household have access to the information of pets in the same household."/>
        <Widget directionXS="row" title="Find peace of mind" icon="fa fa-yin-yang" text="We know that an app can’t make all of your pet’s health concerns go away, but Luna Journal aims to do as much as we can to alleviate that stress."/>
      </Col>
    </Row>
  </Container>
</About>

)
}